//@ts-nocheck
// 这里是首页数据mixin混入, 单独拿出来tslint不知道this指向, 先禁用检测
import dayjs from "dayjs";
import { FileProps } from "@/interface";
// import getAuthTree from "mock/DocSystem/Back/getAuthTree";

import popupSetMap from "@/views/popup/json/setMap";
let popupKeys = Object.keys(popupSetMap);

let mixin = {
  data() {
    let checkIdList: Array<string | number> = [],
      sortIndicatorList: string[] = ["updateTime"],
      sortRuleList: string[] = ["asc"],
      popupType: string = "createFile",
      menuData: any = require("@/views/mydoc/json/menuData.ts"),
      topCreateList: any[] = menuData.topCreate,
      topFilterList: any[] = menuData.topFilter,
      conversionModeList: any[] = menuData.conversionMode,
      listData: any[] = [],
      resList: any[] = [],
      popupOptions: any = {},
      checkAll: boolean = false,
      visible: boolean = false,
      roundView: boolean = true,
      id: string = 'id',
      teamId: string | undefined = undefined,
      title: string | undefined = undefined,
      syncStorageTime: any = null
    return {
      listData, // 文件列表
      resList, // 后台返回的文件列表
      topCreateList, // 右上角创建文件/文件夹menu
      topFilterList, // 右上角筛选条件menu

      sortIndicatorList, // 文件列表排序条件
      sortRuleList, // 文件列表排序方式
      conversionModeList, // 切换模式按钮

      checkIdList, // 选中文件Id列表

      checkAll, // 标记, 文件是否全选
      visible, // 标记, 是否展示弹窗
      popupType, // 弹窗类型
      popupOptions, //  弹窗options
      roundView, // 是否为平铺视图
      id, // 文件id字段名
      teamId, // 团队id
      title, // 回收站名称
      syncStorageTime // 容量同步定时器
    };
  },
  mounted() {

  },
  provide() {
    return {
      vm: this
    }
  },
  methods: {
    // 获取回收站列表
    getRecycleList() {
      if (this.teamId) {
        console.log(this.$route)
        this.$apis.companyTeamRecycleBinQueryList({
          isPage: 0,
          teamId: this.teamId
        }).then((res) => {
          this.setData(res.data);
        })
      } else {
        this.$apis.queryRecycleList().then((res) => {
          this.setData(res);
        });
      }
    },
    //排序
    setData(res?: any) {
      if (res) {
        // 如果是重新调接口获取的数据
        this.resList = res.list || [];
        // 清空已选文件
        this.checkIdList = [];
      }
      let list: any[] = this.resList;
      this.listData = list.sort((a: FileProps, b: FileProps) => {
        if (b.isDir !== a.isDir) {
          return parseInt(b.isDir) - parseInt(a.isDir); // 排序, 文件夹在前
        } else if (this.sortRuleList[0] === "asc") {
          // 升序
          return this.sortWithIndicator(a, b);
        } else {
          // 降序
          return this.sortWithIndicator(b, a);
        }
      });
    },
    sortWithIndicator(a: any, b: any) {
      let indc = this.sortIndicatorList[0] || "";
      switch (indc) {
        case "userFileName":
          return a[indc].localeCompare(b[indc], "zh-CN"); // 拼音排序
        case "createTime":
        case "updateTime":
          return dayjs(a[indc]).valueOf() - dayjs(b[indc]).valueOf(); // 时间戳排序
        default:
          return parseInt(a[indc]) - parseInt(b[indc]); // 数字/数字型字符串排序
      }
    },
    // 恢复文件
    recoverFile(id: string | number) {
      this.$apis.recoverFile({ id }).then((res) => {
        if (res.code === "200") {
          this.$message.success(`已恢复至个人文档`);
          this.getRecycleList();
        }
      });
    },
    // 批量恢复文件
    batchRecoverFile(ids: string | number) {
      this.$apis.batchRecoverFile({ ids }).then((res) => {
        if (res.code === "200") {
          this.$message.success(`已恢复至个人文档`);
          this.getRecycleList();
        }
      });
    },
    // 团队恢复文件
    teamRecoverFile(ids: string) {
      this.$apis.companyTeamRecycleBinRecover({ ids, teamId: this.teamId }).then((res) => {
        if (res.code === "200") {
          this.$message.success(`文件已恢复`);
          this.getRecycleList();
        }
      });
    },
    // 先查重名
    checkRepeat(id?: string | number = this.checkIdList[0]) {
      // 团队文件
      if (this.teamId) {
        const ids = this.checkIdList.length > 1 ? this.checkIdList : [id]
        this.$apis.companyTeamRecycleBinCheckRepeat({ ids, teamId: this.teamId }).then((res) => {
          if (res.data === false) {
            this.teamRecoverFile(ids);
          } else {
            // 存在重名
            const h = this.$createElement;
            this.$confirm({
              icon: () =>
                h("a-icon", {
                  props: {
                    type: "info-circle",
                    theme: "filled",
                  },
                }),
              title: "恢复文件",
              content: "存在重名文件，是否继续恢复？",
              okText: "继续恢复",
              okType: "primary",
              cancelText: "取消",
              onOk: () => {
                this.teamRecoverFile(ids);
              },
              onCancel: () => {
                this.$message.warning(`已取消`);
              },
            });
          }
        });
      } else {
        if (this.checkIdList.length <= 1) {
          this.$apis.checkRepeat({ id }).then((res) => {
            // 不重名
            if (res.data === false) {
              this.recoverFile(id);
            } else {
              // 存在重名
              const h = this.$createElement;
              this.$confirm({
                icon: () =>
                  h("a-icon", {
                    props: {
                      type: "info-circle",
                      theme: "filled",
                    },
                  }),
                title: "恢复文件",
                content: "存在重名文件，是否继续恢复？",
                okText: "继续恢复",
                okType: "primary",
                cancelText: "取消",
                onOk: () => {
                  this.recoverFile(id);
                },
                onCancel: () => {
                  this.$message.warning(`已取消`);
                },
              });
            }
          });
        } else {
          let ids = this.checkIdList.toString();
          this.$apis.batchCheckRepeat({ ids }).then((res) => {
            if (res.data === false) {
              // 不重名
              this.batchRecoverFile(ids);
            } else {
              // 存在重名
              const h = this.$createElement;
              this.$confirm({
                icon: () =>
                  h("a-icon", {
                    props: {
                      type: "info-circle",
                      theme: "filled",
                    },
                  }),
                title: "恢复文件",
                content: "存在重名文件，是否继续恢复？",
                okText: "继续恢复",
                okType: "primary",
                cancelText: "取消",
                onOk: () => {
                  this.batchRecoverFile(ids);
                },
                onCancel: () => {
                  this.$message.warning(`已取消`);
                },
              });
            }
          });
        }
      }
    },
    // 团队删除文件
    teamDeleteFile(ids) {
      this.$apis.companyTeamRecycleBinDelete({
        ids,
        teamId: this.teamId
      }).then((res) => {
        if (res.code === "200") {
          // 立即调用一次容量
          this.$store.dispatch('user/GET_CAPACITY')
          this.$message.success(`删除成功`);
          this.getRecycleList();
          this.syncStorage()
        }
      });
    },
    //删除文件
    deleteFile(id: string | number) {
      this.$apis.deleteFile({ id }).then((res) => {
        if (res.code === "200") {
          // 立即调用一次容量
          this.$store.dispatch('user/GET_CAPACITY')
          this.$message.success(`已彻底删除1个文件`);
          this.getRecycleList();
          this.syncStorage()
        }
      });
    },
    // 批量删除文件
    batchDeleteFile(ids: string | number) {
      this.$apis.batchDeleteFile({ ids }).then((res) => {
        if (res.code === "200") {
          // 立即调用一次容量
          this.$store.dispatch('user/GET_CAPACITY')
          this.$message.success(`已彻底删除${this.checkIdList.length}个文件`);
          this.getRecycleList();
          this.syncStorage()
        }
      });
    },
    // 容量同步
    syncStorage() {
      if (!this.syncStorageTime)
        this.syncStorageTime = setTimeout(() => {
          this.$store.dispatch("user/GET_USERINFO", 'useStorage');
          this.syncStorageTime = null;
        }, 30000);
    },
    // 全选/清空
    onCheckAll() {
      if (this.checkAll) {
        this.checkIdList = [];
      } else {
        this.checkIdList = this.listData.map((el: any) => el.id);
      }
    },
    cancel() {
      this.checkIdList = [];
    },
    // 文件是否被选中
    isChecked(el: FileProps) {
      return el.id && this.checkIdList.includes(el.id);
    },

    // 双击文件
    toDblclickItem(el: FileProps) {
      // this.$message.info(`双击了${el.name}`);
      // TODO
    },

    toCheckItem(el: FileProps) {
      // 选中/取消选中文件
      if (el.id) {
        let arr: Array<string | number> = this.checkIdList;
        if (arr.includes(el.id)) {
          arr.splice(
            arr.findIndex((item: any) => item === el.id),
            1
          );
        } else {
          arr.push(el.id);
        }
      }
    },
    // 右键
    rightClick(el: FileProps) {
      let arr: Array<string | number> = this.checkIdList;
      if (arr.indexOf(el.id) === -1) {
        arr.splice(0, arr.length);
      }
    },
    checkListItem(checkIdList) {
      // 列表视图选中id
      this.checkIdList = checkIdList;
    },
    sortHandleClick(el: any) {
      if (el.name) {
        if (el.sortFlag) {
          this.sortRuleList = [el.options.asc];
        } else {
          this.sortIndicatorList = [el.options.indicator];
        }
      }
    },
    // 切换列表视图
    changeView() {
      this.$message.info("切换列表视图");
      this.roundView = !this.roundView;
    },
    onPopupClose() {
      this.visible = false;
    },
    // 右键菜单自定义
    onContextMenu(e: any) { },
    // 点击事件
    handleClick(el: any, item: any = {}) {
      console.log(el);
      if (popupKeys.includes(el.func)) {
        this.visible = true;
        this.popupType = el.func;
        this.popupOptions = Object.assign(
          { item, suffix: item.extensionName, checkIdList: this.checkIdList },
          el.options
        );
      } else if (el.func === "conversionMode") {
        this.changeView();
      } else {
        // this.$message.info(`点击了${el.text}`);
        // TODO
      }
    },

    // 点击鼠标右键菜单
    contextmenuClick(el: any) {
      // this.$message.info(`右键点击了${el.text}`);
      this.handleClick(el);
    },
    // 彻底删除确认
    showDeleteConfirm(id?: string | number = this.checkIdList[0]) {
      if (this.checkIdList.length <= 1) {
        const h = this.$createElement;
        this.$confirm({
          icon: () =>
            h("a-icon", {
              props: {
                type: "info-circle",
                theme: "filled",
              },
            }),
          title: "彻底删除",
          content: "即将彻底删除1个文件，删除后将无法恢复，是否确认删除？",
          okText: "确定",
          okType: "primary",
          cancelText: "取消",
          onOk: () => {
            this.teamId ? this.teamDeleteFile([id]) : this.deleteFile(id);
          },
          onCancel: () => {
            this.$message.warning(`已取消`);
          },
        });
      } else {
        let ids = this.checkIdList.toString();
        const h = this.$createElement;
        this.$confirm({
          icon: () =>
            h("a-icon", {
              props: {
                type: "info-circle",
                theme: "filled",
              },
            }),
          title: "彻底删除",
          content:
            "即将彻底删除" +
            this.checkIdList.length +
            "个文件，删除后将无法恢复，是否确认删除？",
          okText: "确定",
          okType: "primary",
          cancelText: "取消",
          onOk: () => {
            this.teamId ? this.teamDeleteFile(this.checkIdList) : this.batchDeleteFile(ids);
          },
          onCancel: () => {
            this.$message.warning(`已取消`);
          },
        });
      }
    },
  },

  filters: {
    // 时间格式化
    timeFormat(time: any) {
      return dayjs(time).format("MM-DD HH:mm");
    },
  },
  watch: {
    // 维护全选状态标记
    checkIdList: {
      handler(val: any[]) {
        this.checkAll = val.length === this.listData.length;
      },
      deep: true,
    },
    sortRuleList: {
      handler(list: any[]) {
        this.setData();
      },
      deep: true,
    },
    sortIndicatorList: {
      handler(list: any[]) {
        this.setData();
      },
      deep: true,
    },
    "$route.query": {
      handler() {
        this.teamId = this.$route.query.teamId;
        this.title = this.$route.params.teamName
        this.getRecycleList();
      },
      deep: true,
      immediate: true
    },
  },
};

export default mixin;
