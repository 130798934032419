
import Vue from 'vue'
import MenuSimple from '../mydoc/menuSimple.vue'
import FileList from './fileList.vue'
import { Radio } from 'ant-design-vue'
// 内容较多, 数据暂存混入文件, 此文件只写模板
import Mixins from './mixin/index'

export default Vue.extend({
  name: 'mydocIndex',
  mixins: [Mixins],
  components: {
    'a-radio': Radio,
    MenuSimple,
    FileList
  },
  props: {},
  data() {
    return {}
  },
  methods: {}
})
