export default {
  example: [
    // menu列表数据json, 这里做字段说明
    {
      text: "", // menu文字
      icon: "", // 文字前, antd的icon
      hicon: "", // 文字前, 系统内的静态资源icon, 一般为小图片
      iconfont: "", // 文字前, 系统引入的iconfont图标
      name: "", // 含语义的唯一标识
      func: "", // 函数名, 点击menu项的时候需要做啥, 详见mydoc/index(handleClick函数)
      topLine: false, // 自定义此项menuitem上方是否添加分割线
      children: [], // 子级, 内部字段同父级
      iconStyle: {}, // 图标样式, 会直接作为文字前icon行内样式
      options: {}, // 其他配置项
      // 特殊字段
      sortFlag: false, // 排序menu中, 升序/降序的item标志, 和(文件名/创建时间等)指标区分开
    },
  ],
  simple: [
    // {
    //   text: "下载",
    //   icon: "",
    //   name: "download",
    //   func: "download",
    // },
    // {
    //   text: "复制",
    //   icon: "",
    //   name: "copy",
    //   func: "copy",
    // },
    // {
    //   text: "移动",
    //   icon: "",
    //   name: "move",
    //   func: "move",
    // },
    // {
    //   text: "移到回收站",
    //   icon: "",
    //   name: "delete",
    //   func: "delete",
    // },
  ],
  // 多选文件右键菜单
  normal: [
    {
      text: "分享",
      icon: "",
      name: "share",
      func: "share",
      functionCode: "VIEW_SHARE",
      subMenu: true,
      children: [
        {
          text: "公开分享",
          icon: "",
          name: "publicShareModal",
          func: "publicShareModal",
          functionCode: "VIEW_SHARE",
        },
        {
          text: "私密分享",
          icon: "",
          name: "privacyShareModal",
          func: "privacyShareModal",
          functionCode: "VIEW_SHARE",
        },
      ],
    },
    {
      text: "添加标签",
      icon: "",
      name: "tag",
      func: "tag",
      functionCode: "VIEW_FILE_TAG",
      topLine: true,
      subMenu: true,
    },
    {
      text: "发送到团队",
      icon: "",
      name: "sendTeam",
      func: "sendTeam",
      functionCode: "COPY_FILE",
      subMenu: true,
      children: [],
    },
    // {
    //   text: "文档工具",
    //   icon: "",
    //   name: "documentTool",
    //   func: "documentTool",
    //   subMenu: true,
    //   children: [
    //     {
    //       text: "PDF转Word",
    //       icon: "",
    //       name: "PDF_Word",
    //       func: "PDF_Word",
    //       disabled: false,
    //     },
    //     {
    //       text: "Word转PDF",
    //       icon: "",
    //       name: "Word_PDF",
    //       func: "Word_PDF",
    //       disabled: false,
    //     },
    //     {
    //       text: "Excel转PDF",
    //       icon: "",
    //       name: "Excel_PDF",
    //       func: "Excel_PDF",
    //       disabled: false,
    //     },
    //     {
    //       text: "PPT转PDF",
    //       icon: "",
    //       name: "PPT_PDF",
    //       func: "PPT_PDF",
    //       disabled: false,
    //     },
    //     {
    //       text: "插入图片",
    //       icon: "",
    //       name: "pdf_edit_addImage",
    //       func: "pdf_edit_addImage",
    //       topLine: true,
    //       disabled: false,
    //     },
    //     {
    //       text: "涂画标注",
    //       icon: "",
    //       name: "pdf_edit_brush",
    //       func: "pdf_edit_brush",
    //       disabled: false,
    //     },
    //     {
    //       text: "PDF签名",
    //       icon: "",
    //       name: "pdf_edit_signature",
    //       func: "pdf_edit_signature",
    //       disabled: false,
    //     },
    //     {
    //       text: "添加水印",
    //       icon: "",
    //       name: "pdf_edit_watermark",
    //       func: "pdf_edit_watermark",
    //       disabled: false,
    //     },
    //   ],
    // },
    {
      text: "收藏",
      icon: "",
      name: "favorite",
      func: "favorite",
      functionCode: "",
    },
    {
      text: "下载",
      icon: "",
      name: "download",
      func: "download",
      functionCode: "DOWNLOAD_FILE",
    },
    {
      text: "复制",
      icon: "",
      name: "copy",
      func: "copy",
      functionCode: "COPY_FILE",
    },
    {
      text: "移动",
      icon: "",
      name: "move",
      func: "move",
      functionCode: "MOVE_FILE",
    },
    {
      text: "移到回收站",
      icon: "",
      name: "delete",
      func: "delete",
      functionCode: "DELETE_FILE",
      fontStyle: {
        color: "#F52F3E",
      },
    },
  ],
  // 单选文件右键菜单
  full: [
    {
      text: "分享",
      icon: "",
      name: "share",
      func: "share",
      functionCode: "VIEW_SHARE",
      subMenu: true,
      children: [
        {
          text: "公开分享",
          icon: "",
          name: "publicShareModal",
          func: "publicShareModal",
          functionCode: "VIEW_SHARE",
        },
        {
          text: "私密分享",
          icon: "",
          name: "privacyShareModal",
          func: "privacyShareModal",
          functionCode: "VIEW_SHARE",
        },
      ],
    },
    {
      text: "添加标签",
      icon: "",
      name: "tag",
      func: "tag",
      functionCode: "VIEW_FILE_TAG",
      topLine: true,
      subMenu: true,
    },
    {
      text: "发送到团队",
      icon: "",
      name: "sendTeam",
      func: "sendTeam",
      functionCode: "COPY_FILE",
      subMenu: true,
      children: [],
    },
    // TODO 文档工具暂时隐藏
    // {
    //   text: "文档工具",
    //   icon: "",
    //   name: "documentTool",
    //   func: "documentTool",
    //   subMenu: true,
    //   children: [
    //     {
    //       text: "PDF转Word",
    //       icon: "",
    //       name: "PDF_Word",
    //       func: "PDF_Word",
    //       disabled: false,
    //     },
    //     {
    //       text: "Word转PDF",
    //       icon: "",
    //       name: "Word_PDF",
    //       func: "Word_PDF",
    //       disabled: false,
    //     },
    //     {
    //       text: "Excel转PDF",
    //       icon: "",
    //       name: "Excel_PDF",
    //       func: "Excel_PDF",
    //       disabled: false,
    //     },
    //     {
    //       text: "PPT转PDF",
    //       icon: "",
    //       name: "PPT_PDF",
    //       func: "PPT_PDF",
    //       disabled: false,
    //     },
    //     {
    //       text: "插入图片",
    //       icon: "",
    //       name: "pdf_edit_addImage",
    //       func: "pdf_edit_addImage",
    //       topLine: true,
    //       disabled: false,
    //     },
    //     {
    //       text: "涂画标注",
    //       icon: "",
    //       name: "pdf_edit_brush",
    //       func: "pdf_edit_brush",
    //       disabled: false,
    //     },
    //     {
    //       text: "PDF签名",
    //       icon: "",
    //       name: "pdf_edit_signature",
    //       func: "pdf_edit_signature",
    //       disabled: false,
    //     },
    //     {
    //       text: "添加水印",
    //       icon: "",
    //       name: "pdf_edit_watermark",
    //       func: "pdf_edit_watermark",
    //       disabled: false,
    //     },
    //   ],
    // },
    {
      text: "收藏",
      icon: "",
      name: "favorite",
      func: "favorite",
      functionCode: "DOWNLOAD_FILE",
    },
    {
      text: "下载",
      icon: "",
      name: "download",
      func: "download",
      functionCode: "DOWNLOAD_FILE",
    },
    {
      text: "复制",
      icon: "",
      name: "copy",
      func: "copy",
      functionCode: "COPY_FILE",
    },
    {
      text: "移动",
      icon: "",
      name: "move",
      func: "move",
      functionCode: "MOVE_FILE",
    },
    {
      text: "重命名",
      icon: "",
      name: "rename",
      func: "rename",
      functionCode: "RENAME_FILE",
    },
    // {
    //   text: "备注描述",
    //   icon: "",
    //   name: "remark",
    //   func: "remark",
    //   functionCode: "REMARK_FILE"
    // },
    {
      text: "移到回收站",
      icon: "",
      name: "delete",
      func: "delete",
      topLine: true,
      functionCode: "DELETE_FILE",
      fontStyle: {
        color: "#F52F3E",
      },
    },
  ],
  // 右键空白菜单
  create: [
    // {
    //   text: "新建文件夹",
    //   hicon: "folder",
    //   name: "createFolder",
    //   func: "createFolder",
    //   iconStyle: {
    //     width: "18px",
    //     marginRight: "10px",
    //   },
    //   functionCode: 'CREATE_FILE'
    // },

    {
      text: "上传文件",
      iconfont: "shangchuanwenjian",
      name: "uploadFile",
      func: "uploadFile",
      iconStyle: {
        fontSize: "18px",
        marginRight: "10px",
      },
      topLine: true,
      functionCode: "UPLOAD_LOCAL_FILE",
    },
    {
      text: "上传文件夹",
      iconfont: "shangchuanwenjianjia",
      name: "uploadFolder",
      func: "uploadFolder",
      iconStyle: {
        fontSize: "18px",
        marginRight: "10px",
      },
      functionCode: "UPLOAD_LOCAL_DIR",
    },
    // {
    //   text: "批量管理",
    //   iconfont: "piliangguanli",
    //   name: "batchManage",
    //   func: "batchManage",
    //   iconStyle: {
    //     fontSize: "18px",
    //     marginRight: "10px",
    //   },
    //   topLine: true,
    // },
  ],
  topCreate: [
    {
      text: "上传",
      iconfont: "shangchuan",
      name: "upload",
      children: [
        {
          text: "上传文件",
          icon: "file",
          name: "uploadFile",
          func: "uploadFile",
        },
        {
          text: "上传文件夹",
          icon: "folder",
          name: "uploadFolder",
          func: "uploadFolder",
        },
      ],
    },
    {
      text: "新建文件夹",
      iconfont: "xinjianwenjianjia",
      name: "createFolder",
      func: "createFolder",
    },
    {
      text: "新建在线文件",
      iconfont: "xinjianzaixianwendang",
      name: "createFolderOnline",
      children: [
        {
          text: "新建文档(wordx)",
          hicon: "doc",
          name: "createDoc",
          func: "createFile",
          iconStyle: {
            width: "16px",
            marginRight: "10px",
          },
          options: {
            suffix: "docx",
          },
        },
        {
          text: "新建表格(excel)",
          hicon: "xls",
          name: "createExcel",
          func: "createFile",
          iconStyle: {
            width: "16px",
            marginRight: "10px",
          },
          options: {
            suffix: "xlsx",
          },
        },
        {
          text: "新建幻灯片(ppt)",
          hicon: "ppt",
          name: "createPpt",
          func: "createFile",
          iconStyle: {
            width: "16px",
            marginRight: "10px",
          },
          options: {
            suffix: "pptx",
          },
        },
      ],
    },
  ],
  // 排序
  topFilter: [
    {
      text: "按创建时间升序",
      iconfont: "shengxu",
      children: [
        {
          text: "名称",
          name: "nameSort",
          func: "sort",
          _value: "userFileName",
          options: {
            indicator: "userFileName",
          },
        },
        {
          text: "创建时间",
          name: "createTimeSort",
          func: "sort",
          _value: "createTime",
          options: {
            indicator: "createTime",
          },
        },
        // {
        //   text: "修改时间",
        //   name: "updateTimeSort",
        //   func: "sort",
        //   _value: "updateTime",
        //   options: {
        //     indicator: "updateTime",
        //   },
        // },
        {
          text: "文件大小",
          name: "sizeSort",
          func: "sort",
          _value: "userFileSize",
          options: {
            indicator: "userFileSize",
          },
        },
        {
          text: "升序",
          name: "ascending",
          func: "sort",
          _value: "asc",
          options: {
            asc: "asc",
          },
          sortFlag: true,
          topLine: true,
        },
        {
          text: "降序",
          name: "descending",
          func: "sort",
          _value: "desc",
          options: {
            asc: "desc",
          },
          sortFlag: true,
        },
      ],
    },
  ],
  // 视图切换
  conversionMode: [
    {
      text: "平铺视图",
      iconfont: "pingpushitu",
      children: [
        {
          text: "平铺视图",
          name: "pingpushitu",
          func: "conversionMode",
          _value: "pingpushitu",
        },
        {
          text: "列表视图",
          name: "liebiaoshitu",
          func: "conversionMode",
          _value: "liebiaoshitu",
        },
      ],
    },
  ],

  /* 团队文件 */
  /* 团队文件额外添加权限校验标识 authority */
  teamNormal: [
    /*     {
          text: "分享",
          icon: "",
          name: "share",
          func: "share",
          authority: true,
          subMenu: true,
          children: [
            {
              text: "公开分享",
              icon: "",
              name: "publicShareModal",
              func: "publicShareModal",
              authority: true,
            },
            {
              text: "私密分享",
              icon: "",
              name: "privacyShareModal",
              func: "privacyShareModal",
              authority: true,
            },
          ]
        },
        {
          text: "协作",
          icon: "",
          name: "cooperation",
          func: "cooperation",
          authority: true,
          subMenu: true,
          children: [
            {
              text: "公开协作",
              icon: "",
              name: "publicCooperation",
              func: "publicCooperation",
              authority: true,
            },
            {
              text: "指定人员协作",
              icon: "",
              name: "rangeCooperation",
              func: "rangeCooperation",
              authority: true,
            }
          ]
        },*/
    {
      text: "添加标签",
      icon: "",
      name: "tag",
      func: "tag",
      topLine: true,
      subMenu: true,
    },
    {
      text: "发送到文档",
      icon: "",
      name: "sendDoc",
      func: "sendDoc",
      functionCode: "COPY_FILE",
    },
    {
      text: "收藏",
      icon: "",
      name: "favorite",
      func: "favorite",
      functionCode: "",
    },
    {
      text: "下载",
      icon: "",
      name: "download",
      func: "download",
    },
    {
      text: "复制",
      icon: "",
      name: "copy",
      func: "copy",
      authority: true,
    },
    {
      text: "移动",
      icon: "",
      name: "move",
      func: "move",
      authority: true,
    },
    {
      text: "移到回收站",
      icon: "",
      name: "delete",
      func: "delete",
      authority: true,
      fontStyle: {
        color: "#F52F3E",
      },
    },
  ],
  teamFull: [
    /*     {
          text: "分享",
          icon: "",
          name: "share",
          func: "share",
          functionCode: "VIEW_SHARE",
          subMenu: true,
          children: [
            {
              text: "公开分享",
              icon: "",
              name: "publicShareModal",
              func: "publicShareModal",
              functionCode: "VIEW_SHARE",
            },
            {
              text: "私密分享",
              icon: "",
              name: "privacyShareModal",
              func: "privacyShareModal",
              functionCode: "VIEW_SHARE",
            },
          ]
        },
        {
          text: "协作",
          icon: "",
          name: "cooperation",
          func: "cooperation",
          functionCode: "DOWNLOAD_FILE",
          subMenu: true,
          children: [
            {
              text: "公开协作",
              icon: "",
              name: "publicCooperation",
              func: "publicCooperation",
              functionCode: "DOWNLOAD_FILE",
            },
            {
              text: "指定人员协作",
              icon: "",
              name: "rangeCooperation",
              func: "rangeCooperation",
              functionCode: "DOWNLOAD_FILE",
            }
          ]
        }, */
    {
      text: "添加标签",
      icon: "",
      name: "tag",
      func: "tag",
      functionCode: "VIEW_FILE_TAG",
      topLine: true,
      subMenu: true,
    },
    {
      text: "发送到文档",
      icon: "",
      name: "sendDoc",
      func: "sendDoc",
      functionCode: "COPY_FILE",
    },
    {
      text: "收藏",
      icon: "",
      name: "favorite",
      func: "favorite",
      functionCode: "DOWNLOAD_FILE",
    },
    {
      text: "下载",
      icon: "",
      name: "download",
      func: "download",
      functionCode: "DOWNLOAD_FILE",
    },
    {
      text: "复制",
      icon: "",
      name: "copy",
      func: "copy",
      functionCode: "COPY_FILE",
    },
    {
      text: "移动",
      icon: "",
      name: "move",
      func: "move",
      functionCode: "MOVE_FILE",
    },
    {
      text: "重命名",
      icon: "",
      name: "rename",
      func: "rename",
      functionCode: "RENAME_FILE",
    },
    // {
    //   text: "备注描述",
    //   icon: "",
    //   name: "remark",
    //   func: "remark",
    //   functionCode: "REMARK_FILE"
    // },
    {
      text: "移到回收站",
      icon: "",
      name: "delete",
      func: "delete",
      topLine: true,
      functionCode: "DELETE_FILE",
      fontStyle: {
        color: "#F52F3E",
      },
    },
  ],
  teamCreate: [],
};
