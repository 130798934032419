export default {
  createFile: {
    title: "新建文件",
    okText: "新建",
    placeholder: "请输入文件名称",
    kindOfPopup: "simple",
  },
  createFolder: {
    title: "新建文件夹",
    okText: "新建",
    placeholder: "请输入文件夹名称",
    kindOfPopup: "simple",
  },
  rename: {
    title: "重命名文件",
    okText: "确定",
    placeholder: "请输入文件名称",
    kindOfPopup: "simple",
  },
  remark: {
    title: "备注文件",
    okText: "确定",
    placeholder: "请输入备注信息",
    kindOfPopup: "simple",
  },
  publicShare: {
    title: "公开分享",
    remark: "所有获得链接的人均可访问此文件",
    kindOfPopup: "share",
  },
  privateShare: {
    title: "私密分享",
    remark: "所有获得链接的人满足特定条件才能访问此文件",
    kindOfPopup: "share",
  },
  publicCollaboration: {
    title: "公开协作",
    remark: "所有获得链接的人均可参与协作",
    kindOfPopup: "collaboration",
  },
  privateCollaboration: {
    title: "指定人员协作",
    remark:
      "获得链接并在协作者名单内的人员才可参与协作，或在申请协作通过后亦可",
    kindOfPopup: "collaboration",
  },
  delete: {
    title: "确认删除",
    msg: "文档删除后，其他人将无法使用，确定移至回收站吗？",
    msg2:'文档删除后，将无法使用，确定移至回收站吗？',
    kindOfPopup: "warning",
  },
  move: {
    title: "移动到",
    remark: "",
    kindOfPopup: "move",
  },
  copy: {
    title: "复制到",
    remark: "",
    kindOfPopup: "copy",
  },
  uploader: {
    title: "上传到",
    remark: "",
    kindOfPopup: "uploader",
  },
  add: {
    title: "新建到",
    remark: "",
    kindOfPopup: "add",
  },
  save: {
    title: "保存到",
    remark: "",
    kindOfPopup: "save",
  }
};
