
const columns = [
  {
    title: "名称",
    dataIndex: "userFileName",
    align: "left",
    scopedSlots: { customRender: "name" },
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    align: "center",
    scopedSlots: { customRender: "createTime" },
    ellipsis: true,
  },
  // {
  //   title: "备注",
  //   dataIndex: "remark",
  //   align: "center",
  //   scopedSlots: { customRender: "comment" },
  // },
  {
    title: "所在文件夹",
    dataIndex: "userFilePath",
    align: "center",
    ellipsis: true,
    customRender: (text: string) => {
      return `${text.slice(0, -1)} `;
    },
  },
  {
    title: "文件大小",
    dataIndex: "userFileSize",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "size" },
  },
];
import Vue from "vue";
import dayjs from "dayjs";
import { Component, Prop, Watch, Ref, Inject } from "vue-property-decorator";
@Component({
  components: {},
  filters: {
    // 时间格式化
    timeFormat(time: string | number) {
      return dayjs(time).format("YYYY-MM-DD HH:mm");
    },
    unitTransform(size: number) {
      let val: string | number;

      if (!size) {
        return "";
      } else {
        if (size < 1024) {
          val = size + "B";
        } else if (size < 1024 * 1024) {
          val = size / 1024;
          val = val.toFixed(2) + "KB";
        } else if (size < 1024 * 1024 * 1024) {
          val = size / (1024 * 1024);
          val = val.toFixed(2) + "MB";
        } else {
          val = size / (1024 * 1024 * 1024);
          val = val.toFixed(2) + "GB";
        }
        return val;
      }
    },
  },
})
export default class ClassComponent extends Vue {
  selectedRowKeys: Array<Number | String> = []; // 多选项
  fileListEleMaxHeight: number = 550;
  browserHeight: number = 0;

  @Prop({ default: () => [] }) listData!: any[]; // 文件列表
  @Prop({ default: () => columns }) columns!: any[]; // 文件列表
  @Prop({ type: Array, default: () => [] }) checkIdList!: any[]; //选中项
  @Prop({ type: Boolean, default: true }) pagination!: boolean; // 是否分页
  @Prop({ type: String, default: "selection" }) selectionType!: string; // 选择类型

  @Inject() readonly vm!: any;
  // 多选按钮
  onSelectChange(selectedRowKeys: Array<Number | string>): void {
    this.selectedRowKeys = selectedRowKeys;
  }
  customRow(record: any) {
    return {
      on: {
        // 事件
        click: () => {
          this.$emit("checkItem", record);
        }, // 点击行
        dblclick: () => {
          this.$emit("dbCheckItem", record);
        },
        contextmenu: (el: any) => {
          // 阻止冒泡, 阻止默认事件.stop.prevent
          el.stopPropagation();
          el.preventDefault();
          // IE兼容
          el.cancelBubble = true;
          el.returnValue = false;

          this.$emit("contextMenu", el, record);
        },
      },
    };
  }
  @Watch("checkIdList", { immediate: true, deep: true })
  onCheckIdChanged(
    val: Array<Number | String>,
    oldVal: Array<Number | String>
  ) {
    this.selectedRowKeys = val;
  }
  @Watch("selectedRowKeys", { immediate: true, deep: true })
  // 同时改变父组件的checkIdList
  onListCheckedChanged(
    val: Array<Number | String>,
    oldVal: Array<Number | String>
  ) {
    this.$emit("checkListItem", val as any);
  }

  // 计算高度
  computedHeightFn() {
    const star_content_fileContainer = document.querySelector(
      ".star-content-fileContainer"
    );
    const star_header_top = document.querySelector(".star-header-top");
    const star_content_fileContainer_height =
      star_content_fileContainer &&
      (star_content_fileContainer as any).offsetHeight;
    const star_header_top_height =
      star_header_top && (star_header_top as any).offsetHeight;
    this.fileListEleMaxHeight =
      star_content_fileContainer_height - star_header_top_height - 20;
    console.log("文档列表滚动的最大高度是多少", this.fileListEleMaxHeight);
  }

  // 用户改变了浏览器高度
  updateBrowserHeight() {
    this.browserHeight = window.innerHeight;
    this.computedHeightFn();
  }

  mounted() {
    this.computedHeightFn();
    window.addEventListener("resize", this.updateBrowserHeight);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.updateBrowserHeight);
  }
}
